.diagnosisSlider .swiper {
  width: 100%;
  height: 100%;
}

.diagnosisSlider .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #3f3636;

  display: flex;
  justify-content: center;
  align-items: center;
}

.diagnosisSlider .mySwiperSlide {
  position: relative;
}

.diagnosisSlider .mySwiperSlide::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
}




.diagnosisSlider .mySwiperSlide2::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(78, 78, 78, 0.13);
  pointer-events: none;
}





.diagnosisSlider .swiper-button-next,
.diagnosisSlider .swiper-button-prev {
  top: auto !important;
  bottom: 20px !important;
  width: auto !important;
}

.diagnosisSlider .swiper-button-next {
  left: auto !important;
  right: 20px !important;
}

.diagnosisSlider .swiper-button-prev {
  left: auto !important;
  right: 70px !important;
}

.diagnosisSlider .swiper-button-next::after,
.diagnosisSlider .swiper-button-prev::after {
  color: white !important;
  fill: white !important;
}

/* max-width: 768px */

@media (max-width: 768px) {
  .diagnosisSlider .swiper-button-next,
  .diagnosisSlider .swiper-button-prev {
    bottom: 30% !important;
    /* transform: translateY(50%); */
  }

  .diagnosisSlider .swiper-button-next {
    left: auto !important;
    right: 0px !important;
  }

  .diagnosisSlider .swiper-button-prev {
    left: 0 !important;
    right: auto !important;
  }

  .diagnosisSlider .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #3f3636;

    display: flex;
    justify-content: center;
    align-items: start;
  }
}
