@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

body {
  background-color: rgb(247, 244, 244);
}

/* Pagination */

.page-item {
  display: inline-block;
  margin-right: 5px;
  border-radius: 5px;
  background-color: #70c9dd;
  padding: 5px 10px;
}

.page-item.active {
  background-color: #027f9b;
  color: white;
}

.page-link {
  color: white;
}

.tableColor {
  
  font-size: 12px;
  border-color: rgb(0, 159, 193);
  border-width: 1px;
  text-align: center; 
  padding: 8px; 
  font-weight: bold;
}
.tableHead{
  color: rgb(0, 159, 193);;
  
}


@media (max-width: 768px) {
  .aboutBannerImg {
    object-position: 0 0;
  }
  .page-item {
    margin-right: 2px;
    background-color: #70c9dd;
    padding: 4px 12px;
  }
}
