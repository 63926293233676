.bg-opacity-layer {
  position: relative !important;
}

.bg-opacity-layer::before {
  content: "" !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  background-image: inherit !important;
  background-size: 50% auto;
  background-repeat: no-repeat;
  background-position: -15vw center;
  opacity: 0.4 !important;
  z-index: 0 !important;
}
