.about-us .swiper {
  width: 100%;
  height: 100%;
}

.about-us .swiper-slide {
  /* text-align: center; */
  background: #fff;
  border-bottom: 12px solid #049bbc;

  /* Center slide text vertically */
}

.about-us .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

